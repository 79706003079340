
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsNnyDSffT5xMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93xl5teH5zlqMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor1kVqCqY34sMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/blog/editor.vue?macro=true";
import { default as indexSSTP61453aMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/index.vue?macro=true";
import { default as moderationPteByNaw1cMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/moderation.vue?macro=true";
import { default as pendingBans5nr6FMGIxNMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/pendingBans.vue?macro=true";
import { default as profilesOmC7bkwQuRMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/profiles.vue?macro=true";
import { default as expensespdGGBbPvcQMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexD3e6bp5AXbMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewY3IgFXkNTQMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaiting4EYtr3LcBFMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingZxSTpT0gudMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/translations/missing.vue?macro=true";
import { default as userswqeOMiiedIMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/users.vue?macro=true";
import { default as apimzQjEbBUgGMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/api.vue?macro=true";
import { default as _91slug_93JZWXFS0KY1Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/blog/[slug].vue?macro=true";
import { default as indexTjAw6heTVbMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_934uWvjQZaQ8Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93cGy05EqgOjMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminFII52EH3PkMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/census/admin.vue?macro=true";
import { default as indexaDDB6qGgjZMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/census/index.vue?macro=true";
import { default as contact27lHU5SE7zMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/contact.vue?macro=true";
import { default as designyDw7iO9XseMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/design.vue?macro=true";
import { default as english3SDkfahD6xMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/english.vue?macro=true";
import { default as faqedk1PG62EpMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/faq.vue?macro=true";
import { default as inclusiverWreQUAsNbMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/inclusive.vue?macro=true";
import { default as index_45home68VShKtURuMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/index-home.vue?macro=true";
import { default as index_45localev9dAwakbLiMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/index-locale.vue?macro=true";
import { default as langswitchsl4ILb9sZEMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/langswitch.vue?macro=true";
import { default as licensefhxQyJQzRgMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/license.vue?macro=true";
import { default as academictsqXnEahkJMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/academic.vue?macro=true";
import { default as indexxCl7KTUfLNMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/index.vue?macro=true";
import { default as mediagKeMW1CU3WMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/media.vue?macro=true";
import { default as translinguisticscRZZPCoySZMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/translinguistics.vue?macro=true";
import { default as zined4kyXx1sNwMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/zine.vue?macro=true";
import { default as namessX2vEpVhV5Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/names.vue?macro=true";
import { default as indexvz8hKT7MguMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/nouns/index.vue?macro=true";
import { default as templatesJrGqGMxlUgMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/nouns/templates.vue?macro=true";
import { default as peoplegnbrmtharuMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/people.vue?macro=true";
import { default as privacyLMGWVghBgfMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/privacy.vue?macro=true";
import { default as _91username_93oEnZdSYFPcMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93vEkTLcAdHsMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/card-[username].vue?macro=true";
import { default as editorgWI0vskZduMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/editor.vue?macro=true";
import { default as anyOKUHqzy845Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/any.vue?macro=true";
import { default as askOjz4aSYTmeMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/ask.vue?macro=true";
import { default as avoiding2HSlQMrI5EMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/avoiding.vue?macro=true";
import { default as indexo9buzgHQoHMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/index.vue?macro=true";
import { default as mirrorUlMaeoLfH6Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun9bvg384JRoMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/pronoun.vue?macro=true";
import { default as sources9KZ4ExiQRyMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/sources.vue?macro=true";
import { default as teamNqG3zlN0ZbMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/team.vue?macro=true";
import { default as terminology933dVWa09xMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/terminology.vue?macro=true";
import { default as termsxTqo4axFbnMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/terms.vue?macro=true";
import { default as userJ7IZhnMKU9Meta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/user.vue?macro=true";
import { default as workshops4gjNeRjhCUMeta } from "/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsNnyDSffT5xMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans5nr6FMGIxNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apimzQjEbBUgGMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93JZWXFS0KY1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexTjAw6heTVbMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_934uWvjQZaQ8Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93cGy05EqgOjMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminFII52EH3PkMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexaDDB6qGgjZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact27lHU5SE7zMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: english3SDkfahD6xMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqedk1PG62EpMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiverWreQUAsNbMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45home68VShKtURuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localev9dAwakbLiMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchsl4ILb9sZEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academictsqXnEahkJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexxCl7KTUfLNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediagKeMW1CU3WMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticscRZZPCoySZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zined4kyXx1sNwMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namessX2vEpVhV5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexvz8hKT7MguMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesJrGqGMxlUgMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplegnbrmtharuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyLMGWVghBgfMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93oEnZdSYFPcMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93vEkTLcAdHsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorgWI0vskZduMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyOKUHqzy845Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askOjz4aSYTmeMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding2HSlQMrI5EMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexo9buzgHQoHMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorUlMaeoLfH6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun9bvg384JRoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources9KZ4ExiQRyMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamNqG3zlN0ZbMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminology933dVWa09xMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsxTqo4axFbnMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userJ7IZhnMKU9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshops4gjNeRjhCUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/2025-02-09--11-49-32/pages/workshops.vue")
  }
]